import { FormattedMessage, useIntl } from "gatsby-plugin-intl";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { Grid, makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import FormularioNaranjo from "../components/formularios/formularioNaranjo";
import NoDisponible from "../components/formularios/NoDisponible";
import { campingNaranjo, cerrado, costosNaranjo, feriados, horasNaranjo, inputsNaranjo } from "../components/defaults";
import React from "react";

const useStyles = makeStyles(() => ({
    highlight: {
        backgroundColor: "yellow",
    },
}));

const SantaRosaF = () => {
    const sectorACG = "Naranjo";
    const intl = useIntl();
    const fechasCerrado = cerrado[sectorACG];

    const classes = useStyles();

    return (
        <Layout sector={sectorACG}>
            <Seo title={intl.formatMessage({ id: "sr.titulo" })}
                description={intl.formatMessage({ id: "index.naranjo" })}
                image={"/images/naranjo.jpg"}
            />
            <Grid container>
                <Grid item md={12}>
                    <Typography variant={"h6"} component={"h2"} >
                        {intl.formatMessage({ id: "Naranjo" })}
                    </Typography> <br />

                    {(new Date('12/6/2023') > new Date()) && (
                        <di>
                            <Typography variant={"body2"}> <strong>
                                <FormattedMessage
                                    id="nj.cierre"
                                    values={{
                                        a: text => <a href={"https://www.acguanacaste.ac.cr/noticias/noticias-programa-de-ecoturismo/5874-cierre-temporal-de-acceso-a-playa-naranjo-parque-nacional-santa-rosa"} target={"_blank"} rel={"noreferrer"}>{text}</a>,
                                    }}
                                />
                            </strong>
                            </Typography>
                            <br />
                        </di>

                    )}


                    <Typography variant={"body2"}>
                        <FormattedMessage
                            id="nj.info1"
                            values={{
                                a: text => <a href={"https://www.acguanacaste.ac.cr/turismo/sector-santa-rosa/playa-naranjo-sector-santa-rosa"} target={"_blank"} rel={"noreferrer"}>{text}</a>,
                            }}
                        />
                    </Typography> <br />
                    <Typography variant={"body2"}>
                        {intl.formatMessage({ id: "nj.ninfo2" })}
                    </Typography> <br />
                    <Typography variant={"body2"}>
                        {intl.formatMessage({ id: "nj.info3" })}
                    </Typography> <br />
                    <Typography variant={"body2"} className={classes.highlight}>
                        {intl.formatMessage({ id: "nj.info4" })}
                    </Typography> <br />
                    <Typography variant={"body2"} className={classes.highlight}>
                        <FormattedMessage
                            id="nj.info5"
                            values={{
                                strong: text => <strong>{text}</strong>,
                            }}
                        />
                    </Typography>

                </Grid>
            </Grid>
            <FormularioNaranjo
                sector={sectorACG}
                horas={horasNaranjo}
                inputFields={inputsNaranjo}
                costos={costosNaranjo}
                pago="Pago con tarjeta al ingresar al Parque"
                diasPevios={0}
                diaCerrado={[2]}
                cerrado={fechasCerrado}
                tipo={"turistas"}
                iva={1}
                feriados={feriados}
                campingNaranjo={campingNaranjo}
            />
            {/* <NoDisponible/> */}
        </Layout>
    )
}

export default SantaRosaF